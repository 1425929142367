@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1500px;
  margin: 0px auto;
  width: 100%;
}

.padding-container {
  padding-inline: 130px;
}

.scrolled {
  background-color: #ffffff; /* Change the background color as desired */
  /* Add other styles for the scrolled state */
  transition: background-color 0.05s ease-in-out;
}

.black-text {
  color: #000000;
  width: 100%;
}

.transparent-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
}
.transparent-header .container {
  display: flex;
  width: 100%;
  padding-block: 20px;
  padding-bottom: 30px;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.transparent-header .container img {
  width: 162px;
}
.transparent-header .container nav ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
}
.transparent-header .container nav ul li .link {
  color: #000000;
}
.transparent-header .container nav .hamburger-container,
.transparent-header .container nav .close-hamburger-container {
  padding: 10px;
  border: 2px solid rgba(25, 24, 37, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}
.transparent-header .container nav .hamburger-container .hamburger,
.transparent-header .container nav .close-hamburger-container .hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}
.transparent-header .container nav .hamburger-container .close-hamburger,
.transparent-header .container nav .close-hamburger-container .close-hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}

.general-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
}
.general-header .container {
  display: flex;
  width: 100%;
  padding-block: 20px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.general-header .container img {
  width: 162px;
}
.general-header .container nav ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
}
.general-header .container nav ul li .link {
  color: #000000;
}
.general-header .container nav .hamburger-container,
.general-header .container nav .close-hamburger-container {
  padding: 10px;
  border: 2px solid rgba(25, 24, 37, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}
.general-header .container nav .hamburger-container .hamburger,
.general-header .container nav .close-hamburger-container .hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}
.general-header .container nav .hamburger-container .close-hamburger,
.general-header .container nav .close-hamburger-container .close-hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}

.facilities-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  row-gap: 50px;
}
.facilities-card-container .facilities-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border-radius: 8px;
}
.facilities-card-container .facilities-card:hover {
  box-shadow: -7px 30px 27px -26px rgba(0, 0, 0, 0.54);
}
.facilities-card-container .facilities-card:hover .facilities-img-container img {
  transform: scale(1.4);
}
.facilities-card-container .facilities-card .facilities-img-container {
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 200px;
}
.facilities-card-container .facilities-card .facilities-img-container img {
  border-radius: 8px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}
.facilities-card-container .facilities-card .facilities-img-container .img-overlay {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(237, 33, 94, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  transition: all 0.4s ease;
  padding: 10px;
}
.facilities-card-container .facilities-card .facilities-img-container .img-overlay h4 {
  font-weight: 500;
  font-size: 20px;
}
.facilities-card-container .facilities-card .facilities-text-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}
.facilities-card-container .facilities-card .facilities-text-content span {
  color: rgba(25, 24, 37, 0.75);
}

.facility-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.facility-popup .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.facility-popup .popup-content {
  background-color: #ffffff;
  z-index: 1000;
  padding: 20px;
  position: relative;
  padding-top: 20px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.facility-popup .popup-content .title {
  font-weight: 400;
  font-size: 24px;
  margin-right: 60px;
  text-transform: capitalize;
}
.facility-popup .popup-content .loader {
  color: rgb(237, 33, 94);
}
.facility-popup .popup-content a {
  color: #ffffff;
  text-decoration: none;
  transition: all 0.4s ease;
  background-color: rgb(237, 33, 94);
  border: 1px solid rgb(237, 33, 94);
  padding: 10px 20px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.facility-popup .popup-content a:hover {
  color: rgb(237, 33, 94);
  background-color: #ffffff;
}
.facility-popup .popup-content h4 {
  font-size: 18px;
  font-weight: 600;
}
.facility-popup .popup-content p {
  font-size: 16px;
}
.facility-popup .popup-content hr {
  margin-top: 10px;
}
.facility-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding-inline: 15px;
  font-size: 40px;
  outline: none;
  border: none;
  transition: all 0.4s ease;
}
.facility-popup .close-btn:hover {
  background-color: rgba(149, 11, 11, 0.831);
  color: #ffffff;
}

.navOpen {
  top: 85px !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 1400px) {
  .general-header .container nav ul li .link,
  .transparent-header .container nav ul li .link {
    font-size: 14px;
  }
}
.border-btn-link {
  color: rgb(237, 33, 94);
  max-width: 300px;
  border: 2px solid rgb(237, 33, 94);
  display: inline-block;
  padding: 20px 50px;
  border-radius: 8px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}

.white-bg-link {
  color: rgb(237, 33, 94);
  max-width: 300px;
  background-color: #ffffff;
  display: inline-block;
  padding: 20px 50px;
  border-radius: 8px;
  font-weight: 600;
}

.pink-bg-link {
  max-width: 300px;
  background-color: rgb(237, 33, 94);
  padding: 20px 50px;
  display: inline-block;
  border-radius: 8px;
  font-weight: 600;
}
.pink-bg-link span {
  color: #ffffff;
}

body {
  font-family: "Poppins", sans-serif;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.testimonial {
  background-color: rgb(237, 33, 94);
}
.testimonial .container {
  display: flex;
  flex-direction: column;
  padding-block: 100px;
  color: #ffffff;
  gap: 50px;
}
.testimonial .container h1 {
  font-weight: 400;
  font-size: 48px;
  text-align: center;
}
.testimonial .container .testimonial-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.testimonial .container .testimonial-cards .testimonial-card {
  display: flex;
  gap: 20px;
}
.testimonial .container .testimonial-cards .testimonial-card .quote-icon-container .quote-icon {
  font-size: 50px;
}
.testimonial .container .testimonial-cards .testimonial-card .testimonial-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.testimonial .container .testimonial-cards .testimonial-card .testimonial-content p {
  line-height: 30px;
}

.health-partner .container {
  padding-block: 100px;
}
.health-partner .container .health-main-container {
  background-color: rgb(255, 247, 249);
  overflow: hidden;
  padding: 50px 40px;
  border-radius: 16px;
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.health-partner .container .health-main-container img {
  position: absolute;
}
.health-partner .container .health-main-container .left-eclipse {
  left: 0;
  bottom: 0;
}
.health-partner .container .health-main-container .right-eclipse {
  right: 0;
  top: 0;
}
.health-partner .container .health-main-container h1 {
  font-size: 32px;
  color: rgb(237, 33, 94);
  font-weight: 600;
  z-index: 2;
}
.health-partner .container .health-main-container p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  z-index: 2;
}
.health-partner .container .health-main-container .pink-bg-link-icon {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-block: 10px;
  z-index: 2;
}
.health-partner .container .health-main-container .pink-bg-link-icon span {
  font-weight: 400;
}
.health-partner .container .health-main-container .pink-bg-link-icon .icon {
  display: flex;
  font-size: 20px;
}

.insurance .container {
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  padding-block: 100px;
}
.insurance .container .insurance-first {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.insurance .container .insurance-first > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
}
.insurance .container .insurance-first > p {
  font-weight: 500;
}
.insurance .container .insurance-img-container {
  width: 50%;
  max-width: 500px;
  min-width: 400px;
}
.insurance .container .insurance-img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

footer .container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding-block: 50px;
}
footer .container .link {
  color: rgba(25, 24, 37, 0.75);
}
footer .container .footer-first {
  color: rgba(25, 24, 37, 0.75);
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 300px;
}
footer .container .footer-first img {
  width: 162px;
}
footer .container .footer-second,
footer .container .footer-third,
footer .container .footer-fourth {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
footer .container .footer-second h3,
footer .container .footer-third h3,
footer .container .footer-fourth h3 {
  font-size: 20px;
}
footer .container .footer-second ul,
footer .container .footer-third ul,
footer .container .footer-fourth ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
footer .container .footer-second ul li,
footer .container .footer-third ul li,
footer .container .footer-fourth ul li {
  color: rgba(25, 24, 37, 0.75);
}

@media screen and (max-width: 1200px) {
  .padding-container {
    padding-inline: 70px;
  }
}
@media screen and (max-width: 950px) {
  .black-text {
    color: #ffffff;
    padding: 20px;
    display: block;
  }
  .general-header .container nav ul,
  .transparent-header .container nav ul {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    background-color: rgb(237, 33, 94);
    flex-direction: column;
    top: 0;
    gap: 0;
    top: -600px;
    transition: all 0.4s ease;
  }
  .general-header .container nav ul li,
  .transparent-header .container nav ul li {
    width: 100%;
  }
  .general-header .container nav ul li .link,
  .transparent-header .container nav ul li .link {
    color: #ffffff;
    width: 100%;
    padding: 20px;
    display: block;
  }
  .general-header .container nav .hamburger-container,
  .transparent-header .container nav .hamburger-container {
    display: flex;
  }
  .padding-container {
    padding-inline: 50px;
  }
  footer .container {
    flex-direction: column;
  }
  .insurance .container {
    flex-direction: column;
  }
  .insurance .container .insurance-first {
    width: 100%;
  }
  .insurance .container .insurance-img-container {
    width: 100%;
    max-width: 700px;
    height: 400px;
  }
  .insurance .container .insurance-img-container img {
    border-radius: 32px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media screen and (max-width: 700px) {
  .facilities-card-container {
    grid-template-columns: 1fr 1fr;
  }
  .health-partner .container .health-main-container {
    flex-direction: column;
    padding-inline: 20px;
    padding-block: 50px;
  }
  .testimonial .container .testimonial-cards {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .facilities-card-container {
    grid-template-columns: 1fr;
  }
  .insurance .container {
    padding-block: 50px;
  }
  .insurance .container .insurance-img-container {
    min-width: 300px;
    height: 300px;
  }
}/*# sourceMappingURL=main.css.map */