@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.home-header {
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.home-header .nav-info {
  background-color: #ffffff;
}
.home-header .nav-info .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-block: 20px;
  align-items: center;
}
.home-header .nav-info .container img {
  width: 162px;
}
.home-header .nav-info .container .contact-info {
  display: flex;
  gap: 50px;
  align-items: center;
}
.home-header .nav-info .container .contact-info > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.home-header .nav-info .container .contact-info > div .icon {
  color: rgb(237, 33, 94);
}
.home-header .nav-info .container .contact-info > div p {
  color: rgba(25, 24, 37, 0.75);
}
.home-header .nav-info .container .hamburger-container,
.home-header .nav-info .container .close-hamburger-container {
  padding: 10px;
  border: 2px solid rgba(25, 24, 37, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}
.home-header .nav-info .container .hamburger-container .hamburger,
.home-header .nav-info .container .close-hamburger-container .hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}
.home-header .nav-info .container .hamburger-container .close-hamburger,
.home-header .nav-info .container .close-hamburger-container .close-hamburger {
  font-size: 24px;
  color: rgba(25, 24, 37, 0.75);
}
.home-header nav {
  background-color: rgb(237, 33, 94);
}
.home-header nav .container {
  width: 100%;
}
.home-header nav .container ul {
  display: flex;
  padding-block: 20px;
  justify-content: center;
  gap: 30px;
}
.home-header nav .container ul li .link {
  color: #ffffff;
}

.home-hero {
  min-height: 100vh;
  height: 100%;
  background: url("../../../public/images/urgent-care-facility.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.home-hero::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.home-hero .container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-block: 100px;
}
.home-hero .container .hero-content {
  max-width: 700px;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
}
.home-hero .container .hero-content > p {
  line-height: 32px;
  font-size: 20px;
}
.home-hero .container .hero-content > p span {
  color: rgb(237, 33, 94);
}
.home-hero .container .hero-content .hero-button {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
}

.offer .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-block: 100px;
  padding-bottom: 0;
}
.offer .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
  text-align: center;
}
.offer .container .offer-main {
  display: flex;
  gap: 70px;
}
.offer .container .offer-main .offer-img-container {
  border-radius: 0 80px 0 80px;
  width: 45%;
  max-width: 500px;
  min-width: 400px;
  height: 200px;
}
.offer .container .offer-main .offer-img-container img {
  border-radius: 0 80px 0 80px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.offer .container .offer-main .offer-text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
  width: 55%;
}
.offer .container .offer-main .offer-text-content > h1 {
  font-size: 40px;
  font-weight: 400;
}
.offer .container .offer-main .offer-text-content > p {
  color: rgba(25, 24, 37, 0.75);
}
.offer .container .offer-main .offer-text-content .styled-text {
  border-left: 5px solid rgb(237, 33, 94);
  padding-left: 20px;
}

.primary-care .container {
  width: 100%;
  display: flex;
  gap: 70px;
  align-items: center;
  padding-block: 100px;
}
.primary-care .container .primary-care-text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.primary-care .container .primary-care-text-content > p {
  color: rgba(25, 24, 37, 0.75);
}
.primary-care .container .primary-care-text-content .primary-care-row {
  display: flex;
  align-items: center;
  gap: 10px;
}
.primary-care .container .primary-care-text-content .primary-care-row .check-icon-container .check-icon {
  color: rgb(237, 33, 94);
  height: 25px;
  width: 25px;
}
.primary-care .container .primary-care-text-content > h1 {
  font-size: 40px;
  font-weight: 400;
}
.primary-care .container .primary-care-img-container {
  border-radius: 0 80px 0 80px;
  width: 50%;
  max-width: 500px;
  min-width: 400px;
}
.primary-care .container .primary-care-img-container img {
  border-radius: 0 80px 0 80px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.occupational .container {
  width: 100%;
  display: flex;
  gap: 70px;
  padding-top: 70px;
  align-items: center;
}
.occupational .container .occupational-img-container {
  width: 50%;
  max-width: 500px;
  min-width: 400px;
  height: 400px;
}
.occupational .container .occupational-img-container img {
  border-radius: 0 80px 0 80px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.occupational .container .occupational-text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.occupational .container .occupational-text-content > h1 {
  font-size: 40px;
  font-weight: 400;
}
.occupational .container .occupational-text-content > p {
  color: rgba(25, 24, 37, 0.75);
}
.occupational .container .occupational-text-content .arrow-row {
  display: flex;
  gap: 20px;
  align-items: center;
}
.occupational .container .occupational-text-content .arrow-row .arrow-icon-container .arrow-icon {
  font-size: 20px;
  color: rgb(237, 33, 94);
}

.facilities .container {
  display: flex;
  flex-direction: column;
  padding-block: 100px;
  align-items: center;
  gap: 50px;
}
.facilities .container > h1 {
  font-size: 48px;
  color: rgb(237, 33, 94);
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 950px) {
  .facilities .container {
    padding-block: 60px;
  }
  .offer .container {
    padding-block: 60px;
  }
  .offer .container .offer-main {
    flex-direction: column-reverse;
    align-items: center;
  }
  .offer .container .offer-main .offer-img-container {
    width: 100%;
    max-width: 700px;
    height: 400px;
    min-width: 300px;
  }
  .offer .container .offer-main .offer-text-content {
    width: 100%;
    max-width: 100%;
  }
  .primary-care .container {
    padding-block: 60px;
    flex-direction: column;
  }
  .primary-care .container .primary-care-img-container {
    width: 100%;
    height: 400px;
    max-width: 700px;
    min-width: 300px;
  }
  .primary-care .container .primary-care-img-container img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-width: 100%;
  }
  .primary-care .container .primary-care-text-content {
    width: 100%;
  }
  .occupational .container {
    padding-block: 60px;
    flex-direction: column-reverse;
  }
  .occupational .container .occupational-img-container {
    width: 100%;
    max-width: 700px;
    height: 400px;
    min-width: 300px;
  }
  .occupational .container .occupational-img-container img {
    width: 100%;
  }
  .occupational .container .occupational-text-content {
    width: 100%;
  }
}
.navOpen {
  top: 85px !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 850px) {
  .home-header .nav-info .container .contact-info {
    display: none;
  }
  .home-header .nav-info .container .hamburger-container {
    display: flex;
  }
  .home-header nav {
    position: fixed;
    right: 0;
    left: 0;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgb(237, 33, 94);
    top: -600px;
    transition: all 0.4s ease;
  }
  .home-header nav .container {
    padding: 0;
  }
  .home-header nav .container ul {
    flex-direction: column;
    gap: 0;
  }
  .home-header nav .container ul .link {
    width: 100%;
    padding: 20px;
    display: block;
    transition: all 0.4s ease;
  }
  .home-header nav .container ul .link:hover {
    background-color: rgb(82, 27, 36);
  }
}
@media screen and (max-width: 700px) {
  .home-hero .container {
    padding-bottom: 50px;
  }
  .home-hero .container .hero-content .hero-button {
    flex-direction: column;
    align-items: center;
  }
  .offer .container .offer-main .offer-img-container {
    height: 300px;
  }
  .primary-care .container .primary-care-img-container {
    height: 300px;
  }
  .occupational .container .occupational-img-container {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  .padding-container {
    padding-inline: 20px;
  }
}/*# sourceMappingURL=home.css.map */